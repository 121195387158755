<template>
  <div class="login">
    <img class="logo" src="../../assets/image/login_img.png" alt="">
    <van-form @submit="onSubmit">
      <van-field v-model="username" name="用户名" label="用户名" placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]">
        <template #label>
          <img v-if="!username" class="form-label" src="../../assets/image/login_user_n.png" alt="">
          <img v-else class="form-label" src="../../assets/image/login_user_s.png" alt="">
        </template>
      </van-field>
      <van-field v-model="password" :type="!showPassword ? 'password' : 'text'" name="密码" label="密码" placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]">
        <template #label>
          <img v-if="!password" class="form-label" src="../../assets/image/login_password_n.png" alt="">
          <img v-else class="form-label" src="../../assets/image/login_password_s.png" alt="">
        </template>
        <template #button>
          <img v-if="!showPassword" class="form-label" @click="visible"
            src="../../assets/image/login_password_no_visible.png" alt="">
          <img v-else class="form-label" @click="visible" src="../../assets/image/login_password_visible.png" alt="">
        </template>
      </van-field>
      <div class="checkbox">
        <van-checkbox v-model="savePassword" icon-size="18px" shape="">记住密码</van-checkbox>
      </div>
      <div class="submit">
        <van-button v-if="!username || !password" round block type="info" class="submit-btn">提交
        </van-button>
        <van-button v-else round block type="info" native-type="submit" class="submit-btn-active submit-btn">提交
        </van-button>
      </div>
      <div class="tip">没有账号密码的话，请联系校内负责照明系统的管理员获取</div>
    </van-form>
  </div>
</template>

<script>
import { login } from '../../http/user/login'
import { manager } from '../../http/user/user'
export default {
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
      savePassword: false,
    }
  },
  methods: {
    visible() {
      this.showPassword = !this.showPassword
    },
    setPassword(data) {
      if (data) {
        let newData = data.split('&&')
        this.setCookie(newData[0], newData[1])
        this.username = newData[0]; //保存到保存数据的地方
        this.password = newData[1];
        this.savePassword = true
      }
    },
    async onSubmit() {
      try {
        this.$toast.loading({
          message: "加载中",
          forbidClick: true
        })
        const LoginRes = await login({
          username: this.username,
          password: this.password
        })
        localStorage.setItem('token', LoginRes.data.accessToken)
        localStorage.setItem('expiresIn', LoginRes.data.expiresIn)
        localStorage.setItem('refreshExpire', LoginRes.data.refreshExpire)
        let u = navigator.userAgent
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (this.savePassword) {
          /* if (isIOS && window.webkit) {
            let data = this.username + '&&' + this.password
            window.webkit.messageHandlers.savePassword.postMessage(data);
          }
          else {
            this.setCookie(this.username, this.password, 7)
          } */
          localStorage.setItem('username', this.username)
          localStorage.setItem('password', this.password)
        }
        else if (!this.savePassword) {
          localStorage.removeItem('username')
          localStorage.removeItem('password')
        }
        const res = await manager()
        this.$store.commit('setSchoolId', res.data.manager.schoolId)
        this.$store.commit('setSchool', res.data.school)
        this.$store.commit('setPermissions', '')
        if (!this.$store.state.school) {
          this.$store.commit('setIsAdmin', true)
          this.$router.push('/selectSchool')
        }
        else {
          this.$store.commit('setIsAdmin', false)
          this.$router.push('/index')
        }
        this.$toast.clear()
      } catch (error) {
        throw (error);
      }
    },
    /* setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date();
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      document.cookie = "userName=" + c_name + ";path=/;expires=" + exdate.toLocaleString()
      document.cookie = "userPassword=" + c_pwd + ";path=/;expires=" + exdate.toLocaleString()
    },
    //获取cookie
    getCookie() {
      let u = navigator.userAgent
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS && window.webkit) {
        window.webkit.messageHandlers.getPassword.postMessage('');
      }
      if (document.cookie.length > 0) {
        var arr = document.cookie.split('; '); //这里显示的格式需要切割一下自己可输出看下
        // console.log(arr)
        for (var i = 0; i < arr.length; i++) {
          var arr2 = arr[i].split('='); //再次切割
          //判断查找相对应的值
          if (arr2[0] == 'userName') {
            this.username = arr2[1]; //保存到保存数据的地方
          } else if (arr2[0] == 'userPassword') {
            this.password = arr2[1];
            this.savePassword = true
          }
        }
      }
    },
    //删除cookie
    clearCookie() {
      this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了

    }, */
  },
  created() {

    window.setPassword = this.setPassword
    if (localStorage.getItem('username')) {
      this.username = localStorage.getItem('username')
      this.password = localStorage.getItem('password')
      this.savePassword = true
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  background-color: #fff;
  height: 100vh;
}

.login-title {
  font-size: 28px;
  font-weight: 500;
  color: #000000;
  line-height: 40px;
  padding-top: 52px;
  margin-left: 16px;
}

.logo {
  width: 100%;
  margin-top: 16px;
}

.form-label {
  height: 28px;
  width: 28px;
}

/deep/ .van-field__label {
  width: auto;
}

/deep/ .van-field__value {
  margin-top: 3px;
}

/deep/ .van-field__button {
  height: 32px;
}

.password-visible {
  width: 28px;
  height: 28px;
  margin-top: 10px;
}

.checkbox {
  margin-left: 16px;
  margin-top: 12px;

  /deep/ .van-checkbox__label {
    font-size: 14px;
    margin-left: 4px;
    color: #999999;
    line-height: 20px;
  }

  /deep/ .van-checkbox__icon .van-icon {
    border-color: #CCCCCC;
    background-color: #fff;
    border-width: 1px;
    border-radius: 50%;
    box-sizing: border-box;
  }
}

.submit {
  margin: 44px 16px;
  margin-bottom: 0;
}

.submit-btn {
  height: 50px;
  background: #CCCCCC;
  border-radius: 6px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  color: #FFFFFF;
}

.submit-btn-active {
  background-color: #378BF7 !important;
}

.tip {
  padding: 0 16px;
  margin-top: 12px;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
}
</style>